import React from 'react'
import { Redirect } from '@reach/router'
import { isLoggedIn } from '../utils/auth'

class PrivateRoute extends React.Component {
  render() {
    const { component: Component, location, ...rest } = this.props
    if (!isLoggedIn()) {
      return <Redirect to="/app/login" noThrow />
    }
    return <Component {...rest} />
  }
}

export default PrivateRoute
