import React from 'react'
import { Depths, MotionAnimations } from '@uifabric/fluent-theme/lib/fluent'
import { getTheme } from 'office-ui-fabric-react/lib/Styling'

const CustomCard = ({ children, styles }) => {
  const theme = getTheme()
  return (
    <div
      style={{
        boxShadow: Depths.depth4,
        padding: '1.5rem',
        borderRadius: '2px',
        backgroundColor: theme.palette.white,
        animation: MotionAnimations.fadeIn,
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        ...styles,
      }}
    >
      {children}
    </div>
  )
}

export default CustomCard
