import React, { useState, useRef } from 'react'
import { useFormik } from 'formik'
import Flag from 'react-world-flags'
import { useToasts } from 'react-toast-office-fabric'
import { useStaticQuery } from 'gatsby'
import { Link } from 'gatsby-plugin-intl'

import * as yup from 'yup'
import Auth from '@aws-amplify/auth'
import { navigate } from '@reach/router'
import {
  AsYouType,
  isValidNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js'

import { PrimaryButton } from 'office-ui-fabric-react/lib/Button'
import { ComboBox } from 'office-ui-fabric-react/lib/ComboBox'
import { Callout, DirectionalHint } from 'office-ui-fabric-react/lib/Callout'
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react'
import { TextField } from 'office-ui-fabric-react/lib/TextField'
import { mergeStyleSets, getTheme } from 'office-ui-fabric-react/lib/Styling'
import { getCommonStyles } from '../styles/components'
import { getParseCountries } from '../utils/helpers'
import VerifyCode from './VerifyCode'

const SignUp = () => {
  const [isRenderVerifyCode, renderVerifyCode] = useState(false)
  const [selectedCountry, setCountry] = useState('')
  const [isCalloutVisible, setCalloutVisible] = useState(false)
  const { add } = useToasts()

  const asYouType = new AsYouType('MX')

  const theme = getTheme()
  const commonClassNames = getCommonStyles()
  const menuInputRef = useRef()

  const {
    allCountriesJson: { edges: countries },
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo_login.png" }) {
        childImageSharp {
          fixed(height: 55) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      allCountriesJson(sort: { fields: [name], order: ASC }) {
        edges {
          node {
            id
            name
            dial_code
            code
          }
        }
      }
    }
  `)
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: {
      username: '',
      password: '',
      email: '',
      phone_number: '',
    },
    onSubmit: async values => {
      const { username, password, email, phone_number } = values
      const parsePhonenumber = parsePhoneNumberFromString(phone_number, 'MX')
        .number

      try {
        await Auth.signUp({
          username,
          password,
          attributes: { email, phone_number: parsePhonenumber },
        })
        renderVerifyCode(true)
      } catch (err) {
        add({ type: 'error', message: err.message, timeLeft: 3000 })
        // console.log('error signing up...', err)
      }
    },
    validationSchema: yup.object().shape({
      username: yup.string().required('Username is required'),
      password: yup.string().required('This field is require'),
      email: yup
        .string()
        .email('Email is not correct')
        .required('This field is require'),
      phone_number: yup
        .string()
        .test('phone_number', 'Phone number is not valid', value => {
          if (!value) {
            return true
          }
          return isValidNumber(value, 'MX')
        }),
    }),
  })

  const onRenderOption = item => {
    if (
      item.itemType === SelectableOptionMenuItemType.Header ||
      item.itemType === SelectableOptionMenuItemType.Divider
    ) {
      return <span>{item.text}</span>
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {item.code ? (
          <Flag
            style={{ minWidth: 40 }}
            code={item.code}
            height="14"
            fallback={null}
          />
        ) : null}
        <span style={{ marginLeft: '1rem' }}>{item.text}</span>
      </div>
    )
  }

  const onDismissCallout = () => {
    setCalloutVisible(false)
  }

  const onOpenCallout = () => {
    setCalloutVisible(true)
  }

  const confirmSignUp = async authCode => {
    const { username } = values
    try {
      await Auth.confirmSignUp(username, authCode)
      add({ type: 'success', message: 'Bienvenido!' })
      navigate('/app/login') /** from si esta gaurdado el estado */
    } catch (err) {
      add({ type: 'error', message: err.message, timeLeft: 3000 })
      // console.log('error confirming signing up...', err)
    }
  }

  if (isRenderVerifyCode) return <VerifyCode confirmSignUp={confirmSignUp} />

  return (
    <form
      ref={menuInputRef}
      className={classNames.form}
      onSubmit={handleSubmit}
    >
      <TextField
        onChange={handleChange}
        placeholder="Username"
        name="username"
        value={values.username}
        errorMessage={touched['username'] && errors['username']}
        onBlur={handleBlur}
      />
      <TextField
        onChange={handleChange}
        placeholder="Password"
        name="password"
        value={values.password}
        type="password"
        errorMessage={touched['password'] && errors['password']}
        onBlur={handleBlur}
      />
      <TextField
        onChange={handleChange}
        placeholder="Email"
        name="email"
        value={values.email}
        errorMessage={touched['email'] && errors['email']}
        onBlur={handleBlur}
      />
      <TextField
        onChange={handleChange}
        id="input"
        // componentRef={menuInputRef}
        placeholder="Phone Number"
        onRenderPrefix={() => {
          const maxWidth =
            menuInputRef.current && menuInputRef.current.offsetWidth - 44
          return (
            <div style={{ cursor: 'pointer' }}>
              <div>
                <Flag code="MX" height="14" onClick={onOpenCallout} />
              </div>
              {isCalloutVisible ? (
                <Callout
                  styles={{
                    root: {
                      width: maxWidth,
                    },
                  }}
                  onDismiss={onDismissCallout}
                  target="#input"
                  directionalHint={DirectionalHint.topLeftEdge}
                  coverTarget={true}
                  alignTargetEdge={true}
                  isBeakVisible={false}
                  gapSpace={0}
                  setInitialFocus={true}
                >
                  <ComboBox
                    styles={{
                      header: {
                        backgroundColor: theme.palette.neutralLighter,
                      },
                    }}
                    selectedKey={selectedCountry}
                    placeholder="Search for your country"
                    allowFreeform={true}
                    autoComplete="on"
                    useComboBoxAsMenuWidth
                    options={getParseCountries(countries)}
                    onRenderOption={onRenderOption}
                    onChange={(e, option) => {
                      const { dial_code, key } = option || {}

                      setFieldValue('phone_number', dial_code)
                      setCountry(key)
                      onDismissCallout()
                    }}
                  />
                </Callout>
              ) : null}
            </div>
          )
        }}
        name="phone_number"
        value={asYouType.input(values.phone_number)}
        errorMessage={touched['phone_number'] && errors['phone_number']}
        onBlur={handleBlur}
      />
      <PrimaryButton styles={{ root: { marginTop: '2rem' } }} type="submit">
        Register
      </PrimaryButton>
      <Link
        style={{ margin: '0 auto', paddingTop: '0.75rem' }}
        className={commonClassNames.link}
        to="/app/login"
      >
        Sign In
      </Link>
    </form>
  )
}

const classNames = mergeStyleSets({
  form: {
    display: 'flex',
    flexDirection: 'column',
    selectors: {
      '> .ms-TextField': {
        padding: '1rem 0',
      },
    },
  },
})

export default SignUp
