import React, { useState } from 'react'
import { Link } from 'gatsby-plugin-intl'

import * as yup from 'yup'
import { useFormik } from 'formik'
import { useToasts } from 'react-toast-office-fabric'
import { navigate } from '@reach/router'
import Auth from '@aws-amplify/auth'
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button'
import { TextField } from 'office-ui-fabric-react/lib/TextField'
import { Spinner } from 'office-ui-fabric-react/lib/Spinner'
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling'

import { setUser } from '../utils/auth'
import { getCommonStyles } from '../styles/components'

const SignIn = () => {
  const [loading, setLoading] = useState(false)
  const { add } = useToasts()

  const commonClassNames = getCommonStyles()

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async values => {
      const { username, password } = values
      setLoading(true)

      try {
        await Auth.signIn(username, password)
        const user = await Auth.currentAuthenticatedUser()
        const userInfo = {
          ...user.attributes,
          username: user.username,
        }
        setUser(userInfo)
        navigate('/app/dashboard')
      } catch (err) {
        setLoading(false)
        add({ type: 'error', message: err.message, timeLeft: 3000 })
      }
    },
    validationSchema: yup.object().shape({
      username: yup.string().required('Username is required'),
      password: yup.string().required('Password is required'),
    }),
  })

  return (
    <form onSubmit={handleSubmit} className={classNames.form}>
      <TextField
        onChange={handleChange}
        placeholder="Username"
        name="username"
        value={values.username}
        errorMessage={touched['username'] && errors['username']}
        onBlur={handleBlur}
      />
      <TextField
        onChange={handleChange}
        placeholder="Password"
        name="password"
        value={values.password}
        type="password"
        errorMessage={touched['password'] && errors['password']}
        onBlur={handleBlur}
      />
      <PrimaryButton
        disabled={loading}
        styles={{ root: { marginTop: '2rem' } }}
        type="submit"
      >
        {loading ? (
          <Spinner
            label="Loading..."
            ariaLive="assertive"
            labelPosition="right"
          />
        ) : (
          'Sign In'
        )}
      </PrimaryButton>
      <Link
        style={{ margin: '0 auto', paddingTop: '0.75rem' }}
        className={commonClassNames.link}
        to="/app/login/signup"
      >
        Sign Up
      </Link>
    </form>
  )
}

const classNames = mergeStyleSets({
  form: {
    display: 'flex',
    flexDirection: 'column',
    selectors: {
      '> .ms-TextField': {
        padding: '1rem 0',
      },
    },
  },
})

export default SignIn
