import React from 'react'
import { Router } from '@reach/router'
import { ToastrProvider } from 'react-toast-office-fabric'

import SignIn from '../components/SignIn'
import SignUp from '../components/SignUp'
import PrivateRoute from '../components/PrivateRoute'
import Dashboard from '../components/Dashboard'
import Login from '../components/Login'

const App = () => (
  <ToastrProvider>
    <Router basepath="/app">
      <PrivateRoute path="dashboard/*" component={Dashboard} />
      <Login path="login">
        <SignIn path="/" />
        <SignUp path="signup" />
      </Login>
    </Router>
  </ToastrProvider>
)

export default App
