import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { TextField } from 'office-ui-fabric-react/lib/TextField'
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button'
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling'

const VerifyCode = ({ confirmSignUp }) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues: {
      authCode: '',
    },
    onSubmit: values => {
      const { authCode } = values
      confirmSignUp(authCode)
    },
    validationSchema: yup.object().shape({
      authCode: yup.string().required('Verification code can`t be empty'),
    }),
  })

  return (
    <form className={classNames.form} onSubmit={handleSubmit}>
      <TextField
        onChange={handleChange}
        placeholder="Verification code"
        name="authCode"
        value={values.authCode}
        errorMessage={touched['authCode'] && errors['authCode']}
        onBlur={handleBlur}
      />
      <PrimaryButton styles={{ root: { marginTop: '2rem' } }} type="submit">
        Confirm Sign Up
      </PrimaryButton>
    </form>
  )
}

const classNames = mergeStyleSets({
  form: {
    display: 'flex',
    flexDirection: 'column',
    selectors: {
      '> .ms-TextField': {
        padding: '1rem 0',
      },
    },
  },
})

export default VerifyCode
