import React from 'react'
import { Redirect } from '@reach/router'
import Img from 'gatsby-image'
import {
  mergeStyleSets,
  getTheme,
  FontWeights,
} from 'office-ui-fabric-react/lib/Styling'
import { Text } from 'office-ui-fabric-react/lib/Text'

import { isLoggedIn } from '../utils/auth'
import CustomCard from './common/CustomCard'
import { useStaticQuery } from 'gatsby'

const Login = ({ children, location }) => {
  const classNames = getClassNames()
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo_login.png" }) {
        childImageSharp {
          fixed(height: 55) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  if (isLoggedIn()) {
    return <Redirect to="/app/dashboard" noThrow />
  } /* last route */

  const parseTitle = pathname => {
    switch (pathname) {
      case '/app/login/signup':
        return 'Sign up'
      default:
        return 'Sign in'
    }
  }
  return (
    <div className={classNames.container}>
      <div className={classNames.content}>
        <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl3">
          <CustomCard styles={{ maxWidth: 320 }}>
            <Img
              className={classNames.customImage}
              alt="Logo 2dine"
              fixed={file.childImageSharp.fixed}
            />
            <Text variant="large" className={classNames.subtitle}>
              {parseTitle(location.pathname)}
            </Text>
            {children}
          </CustomCard>
        </div>
      </div>
    </div>
  )
}

const getClassNames = () => {
  const theme = getTheme()

  return mergeStyleSets({
    container: {
      display: 'flex',
      height: '100vh',
    },
    content: {
      flex: 1,
      padding: '1.5rem 1rem',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.palette.neutralLighter,
      alignItems: 'center',
    },
    customImage: {
      margin: '0 auto 4rem auto',
    },
    subtitle: {
      margin: '0 auto',
      fontWeight: FontWeights.semibold,
    },
  })
}

export default Login
