import React, { useEffect } from 'react';
import { Router } from '@reach/router';

import Details from './Details';
import Home from './Home';
import { getCurrentUser } from '../utils/auth';

const Dashboard = () => {
	useEffect(() => {
		const user = getCurrentUser();
		console.log('USER', user);
	}, []);
	return (
		<Router>
			<Home path="/" />
			<Details path="profile" />
		</Router>
	);
};

export default Dashboard;
