import {
  getTheme,
  FontSizes,
  mergeStyleSets,
} from 'office-ui-fabric-react/lib/Styling'

export const getCommonStyles = () => {
  const theme = getTheme()

  return mergeStyleSets({
    link: {
      textDecoration: 'none',
      color: theme.palette.themeSecondary,
      fontSize: FontSizes.medium,
      selectors: {
        '&:hover': {
          color: theme.palette.themeTertiary,
        },
      },
    },
  })
}
